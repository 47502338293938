/* eslint-disable arrow-body-style */
const JSONEditor = require('@json-editor/json-editor').JSONEditor; // eslint-disable-line

export default class JsonEditor {
  protected editor: any;

  public constructor (editorRef: Object, startVal: any) {
    const mergedObj = this.mergeObjectStructure(startVal); // eslint-disable-line
    // eslint-disable-next-line arrow-body-style
    const columnsBond = mergedObj.map((merge: any) => {
      return merge.texts.map((element: any) => {
        return element.field;
      });
    });

    const schema = this.getSchema(columnsBond);
    this.editor = new JSONEditor(editorRef, {
      theme: 'bootstrap4',
      collapsed: true,
      disable_edit_json: true,
      disable_array_delete_all_rows: true,
      disable_array_delete_last_row: true,
      disable_array_reorder: true,
      no_additional_properties: true,
      enable_array_copy: true,
      schema: schema,
      startval: mergedObj
    });
  }

  public getSchema = (columnsBond: any): Object => (
    {
      title: 'Documentos',
      type: 'array',
      items: {
        type: 'object',
        options: {
          collapsed: true
        },
        headerTemplate: '{{ self.name }}',
        properties: {
          name: {
            type: 'string',
            title: 'Nome',
            default: ''
          },
          background: {
            type: 'string',
            title: 'Imagem de fundo',
            default: ''
          },
          width: {
            title: 'Largura',
            type: 'string',
            default: '54.03'
          },
          height: {
            title: 'Comprimento',
            type: 'string',
            default: '85.65'
          },
          isFrontAndBack: {
            title: 'Impressão frente e verso',
            type: 'boolean',
            format: 'checkbox'
          },
          saveMirrorValue: {
            title: 'Salvar vinculo com Estoque',
            type: 'boolean',
            format: 'checkbox',
            default: false
          },
          fieldToSave: {
            title: 'Campo que deverá ser salvo como vinculo',
            watch: {
              templateFields: 'template_fields'
            },
            enumSource: columnsBond
          },
          imagens: {
            type: 'array',
            title: 'Imagens',
            format: 'table',
            items: {
              type: 'object',
              properties: {
                description: {
                  title: 'Descrição',
                  type: 'string'
                },
                defaultValue: {
                  title: 'default url',
                  type: 'string'
                },
                x: {
                  type: 'number'
                },
                y: {
                  type: 'number'
                },
                width: {
                  type: 'number'
                },
                height: {
                  type: 'number'
                },
                rotate: {
                  type: 'string'
                },
                borderRadius: {
                  type: 'number'
                }
              }
            }
          },
          texts: {
            type: 'array',
            title: 'Textos',
            format: 'table',
            id: 'texts',
            items: {
              type: 'object',
              properties: {
                field: {
                  type: 'string',
                  title: 'Descrição'
                },
                x: {
                  type: 'number'
                },
                y: {
                  type: 'number'
                },
                size: {
                  type: 'string'
                },
                font: {
                  type: 'string'
                },
                maxWidth: {
                  type: 'string'
                },
                fontWeight: {
                  type: 'string',
                  enum: ['', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'bold', 'bolder', 'inherit', 'lighter', 'normal', 'revert', 'unset'],
                  default: ''
                },
                textAlign: {
                  type: 'string',
                  enum: ['', 'center', 'end', 'inherit', 'initial', 'justify', 'left', 'revert', 'right', 'start', 'unset'],
                  default: ''
                },
                whiteSpace: {
                  type: 'string',
                  enum: ['', 'break-spaces', 'inherit', 'initial', 'normal', 'normal', 'nowrap', 'pre', 'pre-line', 'pre-wrap', 'revert', 'unset'],
                  default: ''
                },
                color: {
                  type: 'string',
                  title: 'Cor (texto/hexadecimal)'
                },
                lineHeight: {
                  title: 'Distância entre linhas',
                  type: 'string'
                },
                character: {
                  type: 'string',
                  title: 'Quantidade letras'
                },
                endLine: {
                  type: 'string',
                  title: 'Pular linha após'
                },
                rotate: {
                  type: 'string'
                },
                letterSpacing: {
                  type: 'string',
                  title: 'Espaçamento entre letras'
                },
                forceUppercase: {
                  type: 'boolean',
                  format: 'checkbox',
                  default: false
                }
              }
            }
          },
          dictionary: {
            type: 'array',
            title: 'Dicionário',
            format: 'table',
            items: {
              type: 'object',
              properties: {
                identifier: {
                  type: 'string',
                  title: 'Identificador'
                },
                field: {
                  type: 'string',
                  title: 'Campo a ser traduzido'
                },
                description: {
                  type: 'string',
                  title: 'Descrição'
                }
              }
            }
          },
          titles: {
            type: 'array',
            title: 'Títulos',
            format: 'table',
            items: {
              type: 'object',
              properties: {
                field: {
                  type: 'string',
                  title: 'Descrição'
                },
                x: {
                  type: 'string'
                },
                y: {
                  type: 'string'
                },
                size: {
                  type: 'string'
                },
                font: {
                  type: 'string'
                },
                fontWeight: {
                  type: 'string',
                  enum: ['', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'bold', 'bolder', 'inherit', 'lighter', 'normal', 'revert', 'unset'],
                  default: ''
                },
                color: {
                  type: 'string',
                  title: 'Cor (texto/hexadecimal)'
                },
                character: {
                  type: 'string',
                  title: 'Quantidade letras'
                },
                endLine: {
                  type: 'string',
                  title: 'Pular linha após'
                },
                rotate: {
                  type: 'string'
                },
                forceUppercase: {
                  type: 'boolean',
                  format: 'checkbox',
                  default: false
                }
              }
            }
          },
          layout: {
            type: 'object',
            title: 'Configurações interna da folha',
            properties: {
              width: {
                type: 'number',
                title: 'Largura',
                prefix: 'mm'
              },
              height: {
                type: 'number',
                title: 'Altura'
              },
              leftMargin: {
                type: 'number',
                title: 'Margem da esquerda'
              },
              rightMargin: {
                type: 'number',
                title: 'Margem da direita'
              },
              topMargin: {
                type: 'number',
                title: 'Margem do topo'
              },
              bottomMargin: {
                type: 'number',
                title: 'Margem de baixo'
              },
              documentLeftMargin: {
                type: 'number',
                title: 'Distância do documento da esquerda'
              },
              documentBottomMargin: {
                type: 'number',
                title: 'Distância do documento de baixo'
              }
            }
          },
          pageConfig: {
            type: 'object',
            title: 'Margens externas da folha',
            properties: {
              externalLeftMargin: {
                type: 'number',
                title: 'Esquerda',
                prefix: 'mm'
              },
              externalRightMargin: {
                type: 'number',
                title: 'Direita',
                prefix: 'mm'
              },
              externalTopMargin: {
                type: 'number',
                title: 'Topo',
                prefix: 'mm'
              },
              externalBottomMargin: {
                type: 'number',
                title: 'Baixo',
                prefix: 'mm'
              }
            }
          },
          printConfig: {
            type: 'object',
            title: 'Configurações de impressão',
            properties: {
              documentsPerPage: {
                type: 'number',
                title: 'Documentos por página'
              },
              maxPerLine: {
                type: 'number',
                title: 'Documentos por linha'
              },
              marginDocRight: {
                type: 'number',
                title: 'Margem do documento à direita',
                prefix: 'mm'
              },
              marginDocBottom: {
                type: 'number',
                title: 'Margem do documento abaixo',
                prefix: 'mm'
              }
            }
          }
        }
      }
    }
  );

  public getAttributeDefaultValue = (type: any): any => {
    let returnValue = null;
    switch (type) {
      case 'object':
        returnValue = {};
        break;
      case 'number':
        returnValue = 0;
        break;
      case 'string':
        returnValue = '';
        break;
      case 'array':
        returnValue = [];
        break;
      case 'boolean':
        returnValue = false;
        break;
      default:
        break;
    }

    return returnValue;
  };

  protected mergeObjectStructure = (defaultProps: any): any => {
    let schema: any = this.getSchema([]);
    schema = schema.items.properties;
    const schemaKeys = Object.keys(schema); // eslint-disable-line
    // Setting the default values for base structure
    for (let i = 0; i < schemaKeys.length; i++) { // eslint-disable-line
      if (schema[schemaKeys[i]].type === 'object') {
        schema[schemaKeys[i]] = {...schema[schemaKeys[i]].properties};
        const objKeys = Object.keys((schema[schemaKeys[i]]));  // eslint-disable-line
        for (let j = 0; j < objKeys.length; j++) { // eslint-disable-line
          schema[schemaKeys[i]][objKeys[j]] = this.getAttributeDefaultValue(schema[schemaKeys[i]][objKeys[j]].type);
        }
      } else {
        schema[schemaKeys[i]] = this.getAttributeDefaultValue(schema[schemaKeys[i]].type);
      }
    }
    // Merging objects
    for (let i = 0; i < defaultProps.length; i++) { // eslint-disable-line
      defaultProps[i] = { ...schema, ...defaultProps[i] };
    }

    return defaultProps;
  };
}

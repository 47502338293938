<template>
  <v-container fluid class='container-fluid'>
    <v-card id='layout'>
      <v-card-title>
        Configuração de layout
        <v-spacer />
        <div>
          <v-btn
            color='secondary'
            class='mr-4'
            @click='confirmModal'
          >
            {{$t('SAVE')}}
          </v-btn>
        </div>
      </v-card-title>
      <v-tabs
        v-model='tabValue'
        background-color='transparent'
        color='basil'
        grow
      >
        <v-tab
          v-for='item in itemsTabs'
          :key='item'
        >
          {{item}}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model='tabValue'>
        <v-tab-item>
          <v-row justify='center' class='mt-3 ml-3 mr-3'>
            <v-col cols='12'>
              <v-card>
                <div id='json-editor' />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-if='tabValue === 1'>
          <v-select
            v-model='selectedPreview'
            :items='itemsTemplate'
            label='Tipo de documento'
            @change='forceRenderCanvas'
          />
          <v-card
            v-if='selectedPreview !== "" && renderCanvas'
            class='ma-4'
            height='600'
            width='500'
          >
            <Canvas
              v-if='renderCanvas'
              :id='selectedPreview'
              :values='mountMockedValues'
              :template='selectedPreview'
              :custom-template='selectedTemplate'
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  // import { JSONEditor } from '@json-editor/json-editor';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import JsonEditor from './JSONEditor';

  export default {
    name: 'Layout',

    data: function () {
      return {
        editor: '',
        template: {},
        selectedPreview: '',
        itemsTabs: ['Configuração', 'Pré visualização'],
        tabValue: 0,
        renderCanvas: true,
      };
    },
    mounted: async function () {
      await this.getAllTemplate();
      await this.mountJson();
    },
    beforeDestroy: function () {
      if (document.getElementById(this.cssId)) {
        document.getElementById(this.cssId).remove();
      }
    },

    components: {
      Canvas: () => import('../Documents/components/Canvas/CNH/Canvas'),
    },

    computed: {
      itemsTemplate: function () {
        if (this.editor === '') return [];

        // eslint-disable-next-line arrow-body-style
        return this.editor.editor.getValue().map((configValue) => {
          return { value: configValue.name, text: configValue.name };
        });
      },
      selectedTemplate: function () {
        const selectedTemplate = this.editor.editor.getValue().find((editorValue) => editorValue.name === this.selectedPreview);

        return selectedTemplate;
      },

      mountMockedValues: function () {
        const jsonReturn = {};
        this.selectedTemplate.texts.forEach((selected) => {
          jsonReturn[selected.field] = 'lorem ipsum';
        });
        this.selectedTemplate.titles.forEach((selected) => {
          jsonReturn[selected.field] = 'lorem title';
        });
        this.selectedTemplate.imagens.forEach((selected) => {
          jsonReturn[selected.description] = 'https://thispersondoesnotexist.com/image';
        });

        return jsonReturn;
      },
    },

    methods: {
      saveConfig: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const config = JSON.stringify(this.editor.editor.getValue(), undefined, 2);

          await axios({
            url: '/template',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              config: config,
            },
          });

          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'success',
            title: this.$t('SUCCESS'),
            text: 'Layout salvo com sucesso',
          });
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading');
          Swal.fire({
            icon: 'error',
            title: this.$t('WARNING'),
            text: 'Tivemos problemas ao salvar layout',
          });
          console.log(e); /* eslint-disable-line no-console */
        }
      },

      forceRenderCanvas: function () {
        this.renderCanvas = false;

        this.$nextTick(() => {
          this.renderCanvas = true;
        });
      },

      getAllTemplate: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/template/1',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.template = JSON.parse(result.data.data.data);
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },

      confirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveConfig();
          }
        });
      },

      mountJson: function () {
        if (!document.getElementById(this.cssId)) {
          const head = document.getElementsByTagName('head')[0];
          const link = document.createElement('link');
          link.id = this.cssId;
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
          head.appendChild(link);
        }

        setTimeout(() => {
          const editor = document.getElementById('json-editor');
          editor.innerHTML = '';
          this.editor = new JsonEditor(editor, this.template);
        }, 500);
      },
    },
  };
</script>
<style>
  .container-fluid {
    max-width: 100% !important;
  }
</style>
